<template>
    <md-table-row :id="'firmware-row-' + firmware.priority">
        <md-table-cell :id="'firmware-row-prio-' + firmware.priority" md-sort-by="priority" md-label="Prio">
            <div>#{{ firmware.priority }}</div>
        </md-table-cell>

        <md-table-cell :id="'firmware-row-firmware-version-' + firmware.priority" class="name-cell"
                       md-label="Firmware version">
            <div>
                <ul>
                    <li v-for="(version, index) in formattedFirmwareVersions" :key="index">{{
                            version
                        }}&nbsp;&nbsp;
                    </li>
                </ul>

                <md-tooltip md-direction="top" class="tool-tip-multi-row">
                    <span>
                        <p>
                            • Last update : {{
                                firmware.last_change_date && TimeUtils.formatTimestampTo(firmware.last_change_date)
                            }}<br/>
                            • By : {{ firmware.last_change_by }}
                        </p>
                    </span>
                </md-tooltip>
            </div>
        </md-table-cell>

        <md-table-cell :id="'firmware-row-recalculated-perimeter-' + firmware.priority"
                       v-if="firmware.perimeter != undefined"
                       class="font-family-cell"
                       md-label="Recalculated perimeter"
        >
            <div class="font-family-cell">
                <span>Countries : {{ firmware.calculatedPerimeterCount.countries }}</span><br/>
                <span>Sites : {{ firmware.calculatedPerimeterCount.stores }}</span><br/>
                <span>Devices : {{ firmware.calculatedPerimeterCount.devices }}</span>
            </div>
        </md-table-cell>
        <md-table-cell v-else :id="'firmware-row-recalculated-perimeter-loading-' + firmware.priority"
                       class="font-family-cell" md-label="Recalculated perimeter">
            <div class="skeleton-cell">
                <v-skeleton-loader type="text@3"/>
            </div>
        </md-table-cell>

        <md-table-cell :id="'firmware-row-policy-update-' + firmware.priority" v-if="firmware.perimeter != undefined"
                       md-label="Policy update" class="table-unset-hidden policy-update-cell">
            <div class="font-family-cell">
                <span class="policy-update"
                      :style="'color: ' + getMetricColor(firmware.policyUpdate)">{{ firmware.policyUpdate }}%</span>
            </div>
        </md-table-cell>
        <md-table-cell v-else :id="'firmware-row-policy-update-loading-' + firmware.priority" class="font-family-cell"
                       md-label="Policy update">
            <div class="skeleton-cell">
                <v-skeleton-loader type="text@3"/>
            </div>
        </md-table-cell>

        <md-table-cell :id="'firmware-row-deployment-' + firmware.priority" v-if="firmware.perimeter != undefined"
                       md-label="Deployment" class="table-unset-hidden deployment-cell">
            <div class="font-family-cell display-inline-flex">
                <UpToDateDeployment :group-firmware-id="firmware.id" :group-firmware-stats="firmware.deploymentStats"/>
            </div>
        </md-table-cell>
        <md-table-cell v-else :id="'firmware-row-deployment-loading-' + firmware.priority" class="font-family-cell"
                       md-label="Deployment">
            <div class="skeleton-cell">
                <v-skeleton-loader type="text@3"/>
            </div>
        </md-table-cell>

        <md-table-cell :id="'firmware-row-action-' + firmware.priority" md-label="Action"
                       class="reduce-padding defaultCell">
            <v-btn :id="'firmware-btn-edit-config-' + firmware.priority" icon
                   @click="$emit('edit-firmware-config', firmware)">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn :id="'firmware-btn-delete-config-' + firmware.priority" icon color="red"
                   @click="$emit('delete-firmware-config', firmware)">
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </md-table-cell>
    </md-table-row>
</template>

<script>
import TimeUtils from '../../../utils/time-utils';
import UpToDateDeployment from './up-to-date-deployment.vue';
import {getMetricColor} from "../../../utils/utils";
import FirmwareUtils from "../../../utils/firmware-utils";
import configUtils from "@/utils/config-utils";

export default {
    name: "FirmwareTableRow",
    components: {
        UpToDateDeployment
    },
    props: {
        firmware: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            TimeUtils,
            getMetricColor,
            firmwareBuildVersionOptions: null
        }
    },
    async mounted() {
        this.firmwareBuildVersionOptions = await configUtils.getObjectEnvVar("VUE_APP_ADMIN_FIRMWARE_ANDROID_VERSIONS");
    },
    computed: {
        formattedFirmwareVersions() {
            return FirmwareUtils.getFormattedFirmwareVersions(this.firmware.firmware_build_versions, this.firmwareBuildVersionOptions);
        }
    }
};
</script>

<style lang="scss" scoped>

.display-inline-flex {
    display: inline-flex;
}

td {
    font-size: 11px;
    vertical-align: middle;
    text-align: center;
    width: max-content;

    &.firmware-version-cell {
        max-width: 120px;
        word-wrap: break-word;
    }

    &.font-family-cell {
        font-family: 'Roboto', sans-serif;
    }

    &.skeleton-cell {
        width: 100%;
    }

    &.policy-update-cell,
    &.deployment-cell {
        font-size: 13px;
        font-weight: bold;
    }
}

.button_delete {
    color: var(--vtmn-color_danger);
}

.policy-update,
.deployment {
    color: #7acf1f;
}
</style>
