<template>
    <div class="popins" >
        <div v-if="display">
            <div class='popin-overlay'></div>
            <div class="popin popin-delete">
                <div class='popin popinRateUs'>
                    <div class='page-title'>RATE US !</div>

                    <div v-if="! feedbackDone" >
                        <p>What is your opinion about this website ?</p>

                        <star-rating  class="justify-center" v-bind:show-rating="true" v-model="globalRate" v-bind:star-size="40" v-bind:increment="1" />

                        <p v-if="!enableSendRateButton()">Help us to improve our application, leave us a comment.</p>

                        <md-field>
                            <label>Comment {{!enableSendRateButton() ? "(minimum 15 characters)" : ""}} :</label>
                            <md-textarea v-model="comment"></md-textarea>
                        </md-field>
                    </div>
                    <div v-else>
                        <div class='popin-text'>Thank you for wanting to give us your feedback but you have already done it for this version of MDM.</div>
                        <div v-if="userRate != ''" class='popin-text'>Last time you gave us {{userRate}}/5 (version : {{rateVersion}})</div>
                    </div>

                    <div class='popin-delete-actions popin-actions'>
                        <md-button id="rate-us-popup-btn-cancel" class="md-raised" @click="closePopup()">Cancel</md-button>
                        <md-button v-if="! feedbackDone" ref="btnSendRate" class="md-raised md-primary" @click="rate" :disabled="!enableSendRateButton()" >OK</md-button>
                    </div>
                </div>
            </div>
        </div>
        <md-dialog-alert v-if="rateAlert" id="alertRate"
                         :md-active.sync="rateAlert"
                         :md-content="rateMessage"
                         md-confirm-text="OK"
        />

    </div>
</template>

<script>
    import ratingService from '../../service/application/rating-service'

    const ONE_DAY = 1000*60*60*24;
    const LAST_POPUP_RATING_DISPLAY_DATE_KEY = "lastPopupRatingDisplayDate";

    export default{
        data : function(){
            return {
                actualVersion: require('../../../package.json'),
                rateAlert: false,
                rateMessage: '',
                feedbackDone: false,
                globalRate: 0,
                comment: '',
                userRate: '',
                rateVersion: '',
                display: false,
            }
        },
        props: {
            popupFeedbackTriggerByHuman: {
                type: Boolean
            }
        },
        watch: {
            rateAlert() {
                if(! this.rateAlert) this.closePopup();
            }
        },
        mounted() {
            this.displayFeedback();
        },
        computed: {
            toSendRate: function () {
                return {
                    ...this.toGetRate,
                    eventTime: new Date().getTime(),
                    env: (process.env.VUE_APP_ENV || "").startsWith('prod') ? "P": (process.env.VUE_APP_ENV === "dev" ? "D": "PP"),
                    country: this.$store.state.user.country,
                    value: this.globalRate,
                    comments: this.comment,
                }

            },
            toGetRate: function () {
                let rate = {
                    app: "com.decathlon.mdm",
                    appVersion: this.actualVersion.version,
                    user: this.$store.state.user.uid,
                }
                return rate;
            }
        },
        methods: {
            closePopup() {
                this.$emit('close');
            },
            displayFeedback() {

                return ratingService.getRate(this.toGetRate).then( rating => {
                    this.userRate = rating?.value;
                    this.rateVersion = rating?.appVersion;

                    const now = new Date().getTime();
                    const lastRatingDate = rating ? rating.eventTime : 0;
                    const lastPopupRatingDisplayDate = localStorage.getItem(LAST_POPUP_RATING_DISPLAY_DATE_KEY)

                    if( this.popupFeedbackTriggerByHuman ||
                        (now-lastRatingDate > ONE_DAY * 90 &&
                         now-lastPopupRatingDisplayDate > ONE_DAY * 30)
                    ){
                        localStorage.setItem(LAST_POPUP_RATING_DISPLAY_DATE_KEY, new Date().getTime());
                        this.display=true;
                    } else {
                        this.closePopup();
                        return;
                    }

                    this.feedbackDone = rating?.appVersion === this.actualVersion.version;
                }).catch(this.manageError);
            },
            enableSendRateButton(){
                return this.globalRate >0 && (this.globalRate > 3 || (this.globalRate <= 3 && this.comment.length > 15));
            },

            rate: function () {
                return ratingService.setRate(this.toSendRate)
                    .then(() => {
                        this.closePopup();
                    }, this.manageError);
            },
            manageError(error) {
                console.error(error);
                this.rateAlert = true;
                this.rateMessage = error?.body?.error ? error.body.error : "An error occurred during rating. Contact the MDM administrator";
            }
        }
    }
</script>
