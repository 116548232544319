import Vue from 'vue';
import {store} from '../../store/store';
import AdminTabsConstant from "@/constant/admin-tabs-constant";

export default {
    async getEolHeader() {
        try {
            const eolHeader = await this.getInformationHeader(AdminTabsConstant.HEADER.CRITICITY_LEVEL_EOL.NAME);
            return eolHeader?.active ? eolHeader : undefined;
        } catch (e) {
            console.warn(`Could not retrieve the ${AdminTabsConstant.HEADER.CRITICITY_LEVEL_EOL.NAME} header, error -> ${JSON.stringify(e)}`);
        }
    },
    async getClassicHeader() {
        return this.getInformationHeader();
    },
    async getInformationHeader(type) {
        return (await Vue.http.get(store.state.urlHeaderInformation + "?type=" + (type || ""))).body;
    },
    async setInformationHeader(information) {
        await Vue.http.post(store.state.urlHeaderInformation, convertIntoHeaderInformation(information));
    }
};

const convertIntoHeaderInformation = (information) => {
    return {
        active: information.active,
        title: information.title,
        content: information.content,
        criticity: information.criticity
    }
}
