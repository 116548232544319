<template>
    <div>

        <md-table v-model="searched" md-sort="name" md-sort-order="asc" md-fixed-header
                  class="device-app-table-style mx-2">

            <md-table-toolbar>
                <v-row>
                    <v-col cols="3" align-self="center">
                        <h1 class="md-title application-title-style">
                            <md-icon class="md-size-1x">apps</md-icon>
                            Applications
                        </h1>
                    </v-col>
                    <v-col align-self="center" v-if="!isKillMdmAppFound">

                        <WebviewChoose :can-change-app="userUtils.canUpdateKioskInfo()"
                                       :can-create-app="userUtils.canCreateApp()"
                                       :display-button="displayNewWebviewButton()"
                                       @webviewSelected="pickWebView"/>

                    </v-col>
                    <v-col align-self="center" cols="6" v-if="!isKillMdmAppFound">
                        <md-field md-clearable class="md-toolbar-section-end">
                            <md-input :placeholder="tooltips.SEARCH_BY_NAME" v-model="search" @input="searchOnTable"/>
                        </md-field>
                    </v-col>
                </v-row>
            </md-table-toolbar>

            <md-table-empty-state
                class="md-accent"
                v-if="!isLoadingApplications && !isKillMdmAppFound"
                md-label="No apps found."
            >
                <md-content v-if="errorFindingApplications" class="md-accent">
                    <ul>
                        <li>We are encountering a problem to find the applications of this device, if the problem
                            persists, please contact your administrator.
                        </li>
                    </ul>
                </md-content>
            </md-table-empty-state>

            <md-table-empty-state v-if="isLoadingApplications" md-label="Loading data" md-description="Please wait..."/>

            <md-table-empty-state class="md-accent" v-else-if="isKillMdmAppFound">
                <md-content class="md-accent">
                    <ul>
                        <li>This device must be migrated to Workspace One, Decathlon's new tool for managing corporate
                            devices. Please click the button below and follow the procedure.
                        </li>
                    </ul>
                </md-content>
                <a :href="`${migrationConstants.WSO_URL}`" target="_blank">
                    <md-button class="md-raised md-primary button-addApplication caption mx-0 my-0 mt-6">
                        GO TO WORKSPACE ONE
                    </md-button>
                </a>
            </md-table-empty-state>

            <md-table-row slot="md-table-row" slot-scope="{ item }"
                          v-if="applications.length > 0 && !isKillMdmAppFound">
                <md-table-cell :md-sort-by="key.id" :md-label="key.displayName" v-for="(key) in getColumnsDefinition">
                    <div v-if="key.id === 'status'">
                        <div v-if="canShowStatus(item)">
                            <md-icon v-if="item[key.id] === 'Not up to date'" style="color: #FFA500;">cached</md-icon>
                            <md-icon v-else-if="item[key.id] === 'Installed'"
                                     style="color: #7CFC00;text-align: center;">done
                            </md-icon>
                            <md-icon v-else style="color :#FF0000;text-align: center;">clear</md-icon>
                            <span>{{ item[key.id] }}</span>
                            <md-tooltip v-if="item['statusTooltips']" md-direction="top">{{
                                    item.statusTooltips
                                }}
                            </md-tooltip>
                        </div>
                        <div v-else>
                            <v-progress-circular v-if="isChangingWebviewUrl" indeterminate color="primary"/>
                        </div>
                    </div>
                    <div v-else-if="key.id === 'icon'">
                        <img :src="item[key.id]" class="application-icon"/>
                    </div>
                    <div v-else-if="key.id === 'defaultApp'">
                        <template v-if="item[key.id] && isChangingDefaultApp">
                            <md-progress-spinner :md-diameter="25" :md-stroke="1" md-mode="indeterminate"/>
                        </template>
                        <template v-else>
                            <md-checkbox v-model="item[key.id]" @change="changeAppDefault(item)"
                                         :disabled="isDisabledCheckbox(item) || isChangingDefaultApp"></md-checkbox>
                            <md-tooltip md-direction="top">{{ item.tooltipAppDefault }}</md-tooltip>
                        </template>
                    </div>
                    <div v-else-if="!$vuetify.breakpoint.xs || key.id === 'name'">
                        <div v-if="key.id === 'currentVersion' && webviewEditing && changingWebViewError != null">
                            <md-icon style="color :#FF0000;text-align: center;">clear</md-icon>
                            <span>Update error !</span>
                            <md-tooltip md-direction="top">{{ changingWebViewError }}</md-tooltip>
                        </div>
                        <div v-else>
                <span v-if="!isAnEditableWebviewField(item, key)" :class="{ 'icon-cursor': canShowEditIcon(item, key) }"
                      :id="'device-details-application-'+key.id+'-'+item.index">
                    {{ displayValue(item, key) }}
                    <i :class="'icon-cursor'" v-if="canShowEditIcon(item, key)" class="ri-pencil-line"
                       @click="openWebviewChooseWindow()"></i>
                </span>
                            <div v-else class="block">
                                <device-app-table-input-edit-webview :appName="item.application" :columnId="key.id"
                                                                     :webview="webview"
                                                                     :isChangingWebviewUrl="isChangingWebviewUrl"/>
                            </div>
                        </div>
                    </div>
                </md-table-cell>
            </md-table-row>
        </md-table>
    </div>
</template>

<script>

import * as Utils from '../../../utils/utils'
import ApplicationApiService from "../../../service/application/application-service";
import FieldConstant from "../../../utils/field-constant";
import ApplicationUtils from "../../../utils/application-utils";
import DefaultAppService from "../../../service/devices/default-app-service";
import KioskInfoService from "../../../service/devices/kiosk-info-service";
import DeviceAppTableInputEditWebview from "./deviceAppTableInputEditWebview";
import WebviewChoose from '../DeviceDetails/webview-choose.vue';
import MessageConstant from '../../../utils/message-constant';
import userUtils from '../../../utils/user-utils';
import migrationConstants from "../../../constant/migration-constants";


export default {
    name: 'deviceAppTable',
    components: {DeviceAppTableInputEditWebview, WebviewChoose},
    data: function () {
        return {
            userUtils: userUtils,
            tooltips: FieldConstant.APPLICATION.TOOLTIPS,
            NO_VALUE: FieldConstant.TEXT.NO_VALUE,
            search: null,
            disabled: true,
            errorFindingApplications: null,
            searched: [],
            applications: [],
            isLoadingApplications: true,
            isChangingWebviewUrl: false,
            changingWebViewError: null,
            isChangingDefaultApp: false,
            webviewEditing: false,
            isKillMdmAppFound: false,
            webview: {
                name: null,
                url: null
            },
            allColumns: [
                {displayName: 'Default app', id: 'defaultApp'},
                {displayName: 'Icon', id: 'icon'},
                {displayName: 'Name', id: 'name', webviewEditable: true},
                {displayName: 'Group', id: 'deploymentGroup', webviewEditable: true},
                {displayName: 'Target', id: 'assignedVersion', webviewEditable: false},
                {displayName: 'Installed', id: 'currentVersion', webviewEditable: false},
                {displayName: 'Status', id: 'status'}
            ],
        }
    },
    mounted: function () {
        this.loadApplications();
    },

    computed: {
        migrationConstants() {
            return migrationConstants
        },
        getColumnsDefinition() {
            let columnsToUse = this.allColumns;

            if (!this.isKiosk) {
                columnsToUse = columnsToUse.filter((elt) => elt.id !== 'defaultApp');
            }

            if (this.$vuetify.breakpoint.xs) {
                const displayColumnForMobile = this.displayColumnForMobile();
                columnsToUse = columnsToUse.filter(({id}) => displayColumnForMobile.includes(id));
            }
            return columnsToUse;
        }
    },
    methods: {
        displayNewWebviewButton() {
            return this.isKiosk && this.device.androidSilentUpdate && !this.$vuetify.breakpoint.xs;
        },
        appListContainsWebviewApp() {
            const app = this.applications.find((application) => {
                return this.isWebViewPackage(application)
            });
            return app != null;
        },
        pickWebView(webview) {
            this.webview = {
                name: webview.name,
                url: webview.url,
                logo: webview.icon,
                package_name: webview.uid
            }
            this.makeChosenWebviewAsDefaultApp();
        },
        canShowEditIcon(item, key) {
            return !this.$vuetify.breakpoint.xs
                && item.type === undefined
                && this.isWebViewPackage(item)
                && (key.id === 'name' || key.id === 'deploymentGroup')
                && item.assignedVersion === this.NO_VALUE
                && userUtils.canUpdateKioskInfo();
        },
        canShowStatus(application) {
            return this.$vuetify.breakpoint.xs || !this.webviewEditing || !this.isWebViewPackage(application);
        },
        makeChosenWebviewAsDefaultApp() {
            this.isChangingWebviewUrl = true;
            this.changingWebViewError = null;

            KioskInfoService.updateKioskInfo({
                device_id: this.$route.params.id,
                device_app_default: this.webview?.package_name,
                webview: {...this.webview}
            })
                .then(() => {
                    this.webviewEditing = false;
                    this.isChangingWebviewUrl = false;
                    this.defaultApp = this.webview?.package_name;
                    this.loadApplications();
                })
                .catch(e => {
                    this.isChangingWebviewUrl = false;
                    this.changingWebViewError = e.bodyText;
                });

        },
        displayValue(app, key) {
            if (',status,currentVersion,assignedVersion,'.includes(key.id) && this.webviewEditing && this.isWebViewPackage(app.application)) {
                return ""
            }
            return app[key.id];
        },
        isWebViewPackage(app) {
            return (app.application?.toString().startsWith(FieldConstant.APPLICATION.WEBAPP.DEFAULT_PACKAGE_NAME)
                    || app.application?.toString().startsWith(FieldConstant.APPLICATION.WEBAPP.DEFAULT_ERROR_PACKAGE_NAME)
                    || app.application?.toString() === FieldConstant.APPLICATION.WEBAPP.DEFAULT_PENDING_PACKAGE_NAME)
                && app.type === undefined;
        },
        initWebview(app) {
            if (this.webview.url === null && this.webview.name === null) {
                this.webview.name = app.name;
                this.webview.url = app.deploymentGroup;
            }
        },
        isAnEditableWebviewField(app, key) {
            if (this.$vuetify.breakpoint.xs || !this.webviewEditing) {
                return false;
            }
            return key.webviewEditable === true && this.isWebViewPackage(app);
        },
        openWebviewChooseWindow() {
            document.getElementById("devices-details-btn-new-webview").click();
        },
        isDisabledCheckbox(application) {
            const needToBeDisabled = this.checkIfNeedToBeDisabled(application);
            application.tooltipAppDefault = needToBeDisabled.tooltips;
            return needToBeDisabled.isDisabled;
        },
        checkIfNeedToBeDisabled(application) {
            if (this.device.androidSilentUpdate) {
                if (!application.playStoreAppReady) {
                    return {tooltips: this.tooltips.PLAYSTORE.NOT_AVAILABLE, isDisabled: true};
                }
            }
            return this.commonDisabledCheck(application);
        },
        commonDisabledCheck(application) {
            let tooltips = this.tooltips.CLICK_TO_CHANGE;
            if (application.defaultApp) {
                tooltips = this.tooltips.ALREADY_RUNNING;
            } else if (ApplicationUtils.applicationCantBeDefaultApp(application.application)) {
                tooltips = this.tooltips.SYSTEM_APP.replace('${appName}', application.name);
            }

            return {
                tooltips,
                isDisabled: application.defaultApp || ApplicationUtils.applicationCantBeDefaultApp(application.application)
            }
        },
        changeAppDefault(application) {
            application.defaultApp = false;
            if (this.commonDisabledCheck(application).isDisabled) {
                Utils.displayAlertDialog(application.name + " can't be a default app");
                return;
            }

            const defaultApps = this.applications.filter(app => app.defaultApp);
            let actualDefaultApp = defaultApps.length > 0 ? defaultApps[0] : {};

            Utils.displayAlertDialogYesNo("Are you sure to use " + application.name + " on this device ?").then(yes => {
                if (yes) {
                    this.isChangingDefaultApp = true;

                    this.applications.forEach((app) => app.defaultApp = false);
                    application.defaultApp = true;

                    DefaultAppService.changeDefaultApp(this.device, application.application, this.$socket, this.connectedToRealtime)
                        .catch(e => {
                            application.defaultApp = false;
                            actualDefaultApp.defaultApp = true;
                            Utils.displayAlertDialog(MessageConstant.ERROR_DURING_APP_CHANGE)
                        }).finally(() => {
                        this.isChangingDefaultApp = false;
                    });

                    if (!this.connectedToRealtime) {
                        Utils.displayAlertDialog(MessageConstant.OK_AT_NEXT_REBOOT)
                    }
                }
            });
        },
        searchOnTable() {
            this.searched = Utils.searchByName(this.applications, this.search)
        },
        loadApplications() {
            this.searched.splice(0, this.searched.length);
            this.applications.splice(0, this.applications.length);

            this.isLoadingApplications = true;

            ApplicationApiService.getApplicationForDevice(this.$route.params.id).then(applications => {
                let label = this.$store.state.labels;

                if (this.isKiosk && this.defaultApp) {
                    const defaultApp = applications.find((application) => application.application === this.defaultApp);
                    if (defaultApp) defaultApp.defaultApp = true;
                }

                let index = 0;
                this.pushAppIntoArray(applications.map(application => {
                    return {
                        ...application,
                        currentVersion: application.currentVersion || label.NO_VERSION,
                        assignedVersion: application.assignedVersion || label.NO_VERSION,
                        deploymentGroup: application.deploymentGroup || label.NO_GROUP,
                        index: ++index
                    }
                }));
            }).catch(e => {
                if (e?.status === 403 && e?.body?.error?.startsWith(`${process.env.VUE_APP_KILL_MDM_APP} found and active`)) {
                    this.isKillMdmAppFound = true;
                    this.$emit('checkIfKillMdmAppExists', this.isKillMdmAppFound);
                } else {
                    this.errorFindingApplications = true;
                }
                this.isLoadingApplications = false;
            });
        },
        pushAppIntoArray(applications) {
            this.applications = applications;
            if (!this.isKillMdmAppFound) this.searched = applications;
            this.isLoadingApplications = false;
        },
        displayColumnForMobile() {
            return ['icon', 'status', 'defaultApp', 'name'];
        }
    },
    props: {
        isKiosk: {
            type: Boolean
        },
        defaultApp: {
            type: String
        },
        device: {
            type: Object
        },
        connectedToRealtime: {
            type: Boolean
        },
    },
    watch: {
        defaultApp: function (newDefaultApp, oldDefaultApp) { // watch defaultApp changed. If changed, modify the application device array value
            this.applications.forEach(application => application.defaultApp = newDefaultApp != undefined && newDefaultApp === application.application)
        },
        device: function () {
            this.loadApplications();
        },
    },
}
</script>

<style lang="scss" scoped>
.md-table-cell {
    vertical-align: middle;
}

.md-dialog {
    max-width: 850px;
    min-height: 550px;
}

.onRight {
    right: 0px;
    position: absolute;
}

.dialog-content {
    margin-top: 50px;
    padding: 20px;
}

.webview-form-button-style {
    min-width: min-content;
}

.application-title-style {
    max-width: min-content;
}

.device-app-table-style {
    overflow-wrap: anywhere;
    border-radius: 0px;
}

</style>
