export default {
    getFormattedFirmwareVersions(firmwareBuildVersions, firmwareBuildVersionOptions) {
        if (Array.isArray(firmwareBuildVersions)) {
            return sortByAndroidVersion(firmwareBuildVersions)
                .map(version => `${version.android_version}: ${getFirmwareDisplayNameFrom(version.firmware_build_version, firmwareBuildVersionOptions)}`);
        }
        return [];
    }
};

export function getFirmwareDisplayNameFrom(firmwareBuildVersion, firmwareBuildVersionOptions) {
    if (!firmwareBuildVersionOptions) return firmwareBuildVersion;
    return firmwareBuildVersionOptions.find(
        firmwareBuildVersionOption => firmwareBuildVersionOption.firmware_build_version === firmwareBuildVersion
    ).firmware_build_display_name;
}

const sortByAndroidVersion = (firmwareBuildVersions) => {
    return firmwareBuildVersions.sort((item1, item2) => {
        const versionA = item1.android_version.split(".").map(Number);
        const versionB = item2.android_version.split(".").map(Number);

        for (let i = 0; i < Math.max(versionA.length, versionB.length); i++) {
            const segmentA = versionA[i] || 0;
            const segmentB = versionB[i] || 0;
            if (segmentA !== segmentB) {
                return segmentA - segmentB;
            }
        }
        return 0;
    });
};
