export default {
    NAME: {
        MIN_LENGTH: 1,
        MAX_LENGTH: 30
    },
    ERROR: {
        NAME: {
            MAX_LENGTH_KO: "The webview name must be less than or equal to 30 characters",
            MIN_LENGTH_KO: "You forgot the webview name !",
        },
        URL: {
            BEGINNING_KO: "The url must begin with https://",
            NOT_VALID: "The url must be a valid url : https://XXXX.XX",
            WEBSITE_NOT_ALLOWED: "This website is not allowed on the webview",
            MUST_BE_VALID: "The url must be a valid one"
        }


    }
};
