<template>
    <img :src="'data:image/png;base64,'+ iconConstants.BASE64.WSO" :width="iconSize" alt="WSO logo"/>
</template>

<script>
import iconConstants from '../../utils/icon-constant';

export default {
    name: "wso-icon",
    props: {
        iconSize: {
            type: Number,
            default: 120
        }
    },
    data() {
        return {
            iconConstants,
        }
    }
}
</script>
