import AdminTabConstant from '../../../constant/admin-tabs-constant';
import InformationHeaderService from '../../../service/header/information-header-service';

const initialState = {
    actualState: {
        preVisualize: false,
        saved: false,
        loading: false,
        error: null,
        first: true
    },
    title: "First header title",
    content: 'You can type here your text with some html tag',
    active: false,
    criticity: AdminTabConstant.HEADER.CRITICITY_LEVEL[0].NAME,
    eol: undefined
}

const state = () => ({
    configuration: initialState
});


const getters = {
    getHeaderConfiguration(state) {
        return state.configuration;
    }
};

const actions = {
    setConfiguration({commit}, configuration) {
        commit('setConfiguration', configuration);
        commit('setActualState', {saved: false, error: null, first: false});
    },
    tooglePreVisualize({commit}, active) {
        commit('tooglePreVisualize', active);
    },
    toogleActive({commit}, active) {
        commit('toogleActive', active);
    },
    resetConfiguration({commit}) {
        commit('resetConfiguration');
    },

    async saveConfiguration({commit, getters}) {
        try {
            commit('setActualState', {loading: true, error: null});

            const headerConfiguration = getters.getHeaderConfiguration;
            await InformationHeaderService.setInformationHeader(headerConfiguration);

            commit('setActualState', {loading: false, saved: true});
        } catch (error) {
            console.error("error when saving header configuration", error);
            errorHandler(error, commit, AdminTabConstant.ERROR.HEADER_CONFIGURATION_SAVING_ERROR);
        }
    },

    async getConfiguration({commit}) {
        try {
            commit('setActualState', {loading: true, error: null, first: true});

            const classicHeader = await InformationHeaderService.getClassicHeader();
            const eol = await InformationHeaderService.getEolHeader();

            commit('setConfiguration', classicHeader);
            eol && commit('setConfiguration', {eol});
            commit('setActualState', {loading: false, saved: true, first: true});
        } catch (error) {
            console.error("error when getting header configuration", error);
            errorHandler(error, commit, AdminTabConstant.ERROR.HEADER_CONFIGURATION_GETTING_ERROR);
        }
    }
};

const errorHandler = (error, commit, defaultMessage) => {
    const errorMessage = error.message || (error.body && error.body.error) || error.body || defaultMessage;
    commit('setActualState', {loading: false, saved: false, error: errorMessage});
}

const mutations = {
    setConfiguration(state, configuration) {
        let addedValue = configuration;
        if (configuration.field) {
            addedValue = {[configuration.field]: configuration.newValue}
        }
        state.configuration = {...state.configuration, ...addedValue};
    },
    setActualState(state, actualState) {
        state.configuration = {
            ...state.configuration,
            actualState: {
                ...state.configuration.actualState,
                ...actualState
            }
        };
    },
    tooglePreVisualize(state, active) {
        state.configuration.actualState.preVisualize = active;
    },
    toogleActive(state, active) {
        state.configuration.active = active;
    },
    resetConfiguration(state) {
        state.configuration = initialState;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
