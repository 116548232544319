<template>
    <v-dialog v-if="data" v-model="data.visible" max-width="50%" max-height="80%">
        <v-card>
            <v-card-title>
                {{ data.type === "update" ? 'Update Firmware' : 'Create New Firmware' }}
            </v-card-title>
            <v-container>
                <v-row justify="center">
                    <v-col sm="12" md="6" align-self="start">
                        <p class="vtmn-typo_title-5">Information</p>
                        <v-text-field v-model="firmware && firmware.priority" label="Firmware priority" type="number"
                                      class="firmware-field-priority"/>

                        <!-- Dynamic Firmware Version Entries -->
                        <p class="firmware-versions-label">Firmware versions</p>
                        <!-- Firmware Version Entries with Scroll -->
                        <div id="firmware-div-versions-container" class="firmware-versions-container">
                            <div v-for="(version, index) in firmware.firmware_build_versions" :key="version.id">
                                <div
                                    :class="['firmware-version', {'duplicated-firmware-version': duplicatedFirmwareVersions.includes(version.id)}]">
                                    <v-select
                                        label="Android version"
                                        v-model="version.android_version"
                                        :items="getAndroidVersions()"
                                        @change="setSingleOption(version, index, 'android_version')"
                                        variant="underlined"
                                    />

                                    <v-select
                                        label="Firmware build version"
                                        v-model="version.firmware_build_display_name"
                                        :items="getFirmwareBuildVersions(version.android_version)"
                                        @change="setSingleOption(version, index, 'firmware_build_version')"
                                        variant="underlined"
                                    />

                                    <v-select
                                        label="Firmware build version URL"
                                        v-model="version.firmware_build_version_url"
                                        :items="[getFirmwareBuildVersionURL(version.android_version, version.firmware_build_display_name)]"
                                        variant="underlined"
                                    />

                                    <!-- Error message for duplicates -->
                                    <span v-if="duplicatedFirmwareVersions.includes(version.id)"
                                          class="duplication-error-message">
                                        This firmware version exists !
                                    </span>
                                </div>

                                <v-btn icon color="red" @click="removeVersion(version.id)"
                                       v-if="firmware.firmware_build_versions.length > 1">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>

                                <!-- Button to Add New Firmware Version, displayed only after the last item -->
                                <v-btn id="firmware-btn-add-version" icon :style="{ color: '#1976d2' }"
                                       @click="addVersion" v-if="index === firmware.firmware_build_versions.length - 1">
                                    <v-icon>mdi-plus-circle</v-icon>
                                </v-btn>
                            </div>
                        </div>
                    </v-col>
                    <v-col sm="12" md="6" align-self="start">
                        <p class="vtmn-typo_title-5">Deployment perimeter</p>
                        <FirmwareArbo :arbo="arbo" @changeArbo='changeArbo' class="firmware-arbo"/>
                    </v-col>
                </v-row>
            </v-container>
            <v-card-actions class="justify-space-around">
                <v-btn color="primary" size="large" @click="_ => data.confirm(firmware) && data.closeDialog()"
                       :disabled="duplicatedFirmwareVersions.length > 0">
                    {{ data.type === "update" ? 'Update' : 'Create' }}
                </v-btn>
                <v-btn size="large" @click="data.closeDialog">Cancel</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import FirmwareArbo from "./firmaware-arbo";
import firmwareService from "@/service/admin/firmware/firmware-service";
import lodash from 'lodash';
import configUtils from "@/utils/config-utils";

export default {
    components: {
        FirmwareArbo
    },
    props: {
        data: {
            type: Object
        }
    },
    data: () => {
        return {
            firmwareBuildVersionOptions: null
        }
    },
    async mounted() {
        this.firmwareBuildVersionOptions = await configUtils.getObjectEnvVar("VUE_APP_ADMIN_FIRMWARE_ANDROID_VERSIONS");
    },
    computed: {
        firmware() {
            return this.data ? this.data.firmware : null;
        },
        arbo() {
            if (!this.data) return null;
            if (this.data.arbo && this.data.firmware.calculatedPerimeter)
                firmwareService.calculateArboByPerimeter(this.data.arbo, this.data.firmware.calculatedPerimeter);
            return this.data.arbo;
        },
        duplicatedFirmwareVersions() {
            const grouped = lodash.groupBy(this.firmware.firmware_build_versions, version => `${version.android_version}_${version.firmware_build_version}`);
            const duplicates = lodash.filter(grouped, group => group.length > 1);
            return lodash.flatten(duplicates.map(group => group.slice(1).map(item => item.id)));
        },
    },
    methods: {
        changeArbo(perimeter) {
            this.firmware.perimeter = perimeter;
        },
        addVersion() {
            this.firmware.firmware_build_versions.push({
                id: Date.now(),
                android_version: '',
                firmware_build_version: '',
                firmware_build_version_url: ''
            })
        },
        removeVersion(id) {
            this.firmware.firmware_build_versions = this.firmware.firmware_build_versions.filter(version => version.id !== id);
        },
        getAndroidVersions() {
            return [...new Set(this.firmwareBuildVersionOptions.map(ele => ele.android_version))];
        },
        getFirmwareBuildVersions(androidVersion, hasToReturnObjects) {
            const firmwaresObjects = [...new Set(this.firmwareBuildVersionOptions
                .filter(ele => ele.android_version === androidVersion))];
            if (hasToReturnObjects) {
                return firmwaresObjects;
            }
            return firmwaresObjects.map(firmwaresObject => firmwaresObject.firmware_build_display_name);
        },
        getFirmwareBuildVersionURL(androidVersion, firmwareBuildVersion, returnObject) {
            const match = this.firmwareBuildVersionOptions.find(firmwareBuildVersionOption =>
                firmwareBuildVersionOption.android_version === androidVersion &&
                firmwareBuildVersionOption.firmware_build_display_name === firmwareBuildVersion
            );
            return match ? (returnObject ? match : match.firmware_build_version_url) : null;
        },
        setSingleOption(version, index, eventSrc) {
            if (eventSrc === "android_version" || eventSrc === "firmware_build_version") {
                version.firmware_build_version = '';
                version.firmware_build_version_url = '';
            }
            const availableBuildVersions = this.getFirmwareBuildVersions(version.android_version, true);
            if (availableBuildVersions.length === 1) {
                version.firmware_build_version = availableBuildVersions[0].firmware_build_version;
                version.firmware_build_display_name = availableBuildVersions[0].firmware_build_display_name;
            }
            const firmwareRowFound = this.getFirmwareBuildVersionURL(version.android_version, version.firmware_build_display_name, true);
            if (firmwareRowFound) {
                version.firmware_build_version_url = firmwareRowFound.firmware_build_version_url;
                version.firmware_build_version = firmwareRowFound.firmware_build_version;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.firmware-versions-label {
    color: rgba(0, 0, 0, .6);
    font-size: 12px
}

.firmware-versions-container {
    width: 80%;
    max-height: 400px;
    overflow-y: auto;
    padding-right: 10px;
}

.firmware-version {
    border: 1px solid #00000099;
    padding: 5px;
    border-radius: 5px;
}

.v-select {
    margin-left: 0;
}

.firmware-field-priority {
    margin-left: 0
}

.firmware-arbo {
    max-height: 500px;
    overflow-y: auto;
}

.duplicated-firmware-version {
    border: 1px solid red;
    padding: 5px;
    border-radius: 5px;
}

.duplication-error-message {
    color: red;
    font-weight: bold;
    margin-top: 5px;
}
</style>
